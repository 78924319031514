<template>
  <div>
    <!-- 上方筛选 -->
    <div class="between">
      <div>
        <span style="margin-right:10px">市场</span>
        <el-select
          v-model="ruleForm.market_id"
          clearable
          placeholder="请选择市场"
        >
          <el-option
            v-for="item in Form.market"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
            @click.native="clickmarket"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span style="margin-right:10px">商户</span>
        <el-select v-model="ruleForm.seller_id" clearable placeholder="请选择">
          <el-option
            v-for="item in Form.SellerByMarket"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span style="margin-right:10px">类目</span>
        <el-select v-model="value.value3" clearable placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span style="margin-right:10px">供应商</span>
        <el-select v-model="value.value4" clearable placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button type="primary">确认</el-button>
      <el-button type="primary" @click="append">新增</el-button>
      <el-button type="primary" @click="addexlx = true">导入</el-button>
    </div>
    <el-table
      :data="tableData.data"
      border
      style="width: 100%;margin-top:20px"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column prop="id" label="编号"> </el-table-column>
      <el-table-column prop="veg_logo" label="商品图片">
        <template slot-scope="scope">
          <img
            style="width:100px;height:50px"
            :src="scope.row.product.veg_logo"
            alt=""
            @click="$refs.img.previewImage(scope.row.product.veg_logo)"
          />
        </template>
      </el-table-column>
      <el-table-column label="商品名称">
        <template slot-scope="scope">
          <span>{{ scope.row.product.veg_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品别名">
        <template slot-scope="scope">
          <span>{{ scope.row.product.veg_alias_name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="market_name" label="所属市场"> </el-table-column>
      <el-table-column prop="seller_name" label="所属商户"> </el-table-column>
      <el-table-column prop="plu" label="电子秤商品序号(plu)">
      </el-table-column>
      <el-table-column prop="veg_price" label="售价">
        <template slot-scope="scope">
          <!-- <span>{{ scope.row.veg_price }}元/{{ scope.row.unit.units }}</span> -->
          <span>{{ scope.row.veg_price }}元</span>
        </template>
      </el-table-column>
      <el-table-column prop="veg_num" label="销量数量"> </el-table-column>
      <el-table-column prop="sku" label="库存">
        <template slot-scope="scope">
          <!-- <span>{{ scope.row.sku }}{{ scope.row.unit.units }}</span> -->
          <span>{{ scope.row.sku }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="supplier_name" label="供应商"> </el-table-column>
      <el-table-column label="商品状态">
        <template slot-scope="scope">
          <el-link v-if="scope.row.product.status * 1 === 0" type="warning"
            >待审核</el-link
          >
          <el-link v-else-if="scope.row.product.status * 1 === 1" type="success"
            >审核通过</el-link
          >
          <el-link v-else type="danger">审核不通过</el-link>
        </template>
      </el-table-column>
      <el-table-column label="产地">
        <template slot-scope="scope">
          <span>{{ scope.row.product.full_address }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="add"
      @close="closeappend"
      width="50%"
      center
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="betten">
          <div>
            <el-form-item label="所在市场" prop="market_id">
              <el-select
                v-model="ruleForm.market_id"
                clearable
                placeholder="请选择市场"
              >
                <el-option
                  v-for="item in Form.market"
                  :key="item.id"
                  :label="item.comp_name"
                  :value="item.id"
                  @click.native="clickmarket"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经营户" prop="seller_id">
              <el-select
                v-model="ruleForm.seller_id"
                clearable
                placeholder="请选择经营户"
              >
                <el-option
                  v-for="item in Form.SellerByMarket"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="clickSellerByMarket"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="店铺" prop="shop_id">
              <el-select
                v-model="ruleForm.shop_id"
                clearable
                placeholder="请选择店铺"
              >
                <el-option
                  v-for="item in Form.ShopBySellerId"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="Shop_BySeller_Id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品分类" prop="product_types_id">
              <el-select
                v-model="ruleForm.product_types_id"
                placeholder="商品分类"
              >
                <el-option
                  v-for="item in Form.CategoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品库商品">
              <el-select
                v-model="ruleForm.veg_id"
                filterable
                clearable
                remote
                :disabled="title === '编辑经营户商品'"
                placeholder="输入关键词搜索商品名称"
                :remote-method="remoteMethod"
                :loading="loading"
                @clear="clearvegname"
              >
                <el-option
                  v-for="item in Form.vegid"
                  :key="item.id"
                  :label="item.veg_name"
                  :value="item.id"
                  @click.native="searchvegid(item)"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品名称" prop="veg_name">
              <el-input
                style="width:217px"
                placeholder="请输入商品名称"
                v-model="ruleForm.veg_name"
                :disabled="!!ruleForm.veg_id"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品别名" prop="veg_alias_name">
              <el-input
                :disabled="!!ruleForm.veg_id"
                style="width:217px"
                placeholder="请输入商品别名"
                v-model="ruleForm.veg_alias_name"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品编号" prop="veg_code">
              <el-input
                :disabled="!!ruleForm.veg_id"
                style="width:217px"
                placeholder="请输入商品编号"
                v-model="ruleForm.veg_code"
              ></el-input>
            </el-form-item>
            <el-form-item label="计量单位" prop="unit_id">
              <el-select
                v-model="ruleForm.unit_id"
                placeholder="请选择计量单位"
              >
                <el-option
                  v-for="item in Form.unitid.data"
                  :key="item.id"
                  :label="item.units_name"
                  :value="item.id"
                  @click.native="unit = item.units"
                ></el-option>
              </el-select>
              <span
                ><el-button @click="add2 = true">添加计量单位</el-button></span
              >
            </el-form-item>
            <el-form-item label="商品数量" prop="sku">
              <el-input
                style="width:217px"
                placeholder="请输入商品数量"
                v-model="ruleForm.sku"
              >
                <i slot="suffix" style="font-style:normal">{{ unit }}</i>
              </el-input>
            </el-form-item>
            <el-form-item label="销售单价" prop="veg_price">
              <el-input
                v-model="ruleForm.veg_price"
                style="width:217px"
                placeholder="请输入内容"
              >
                <i slot="suffix" style="font-style:normal">元/{{ unit }}</i>
              </el-input>
            </el-form-item>
            <el-form-item label="商品图片" prop="veg_logo">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.veg_logo"
                  :src="ruleForm.veg_logo"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="省份" prop="province_id">
              <el-select
                v-model="ruleForm.province_id"
                placeholder="请选择省份"
                :disabled="!!ruleForm.veg_id"
              >
                <el-option
                  v-for="item in Form.provinceid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="getprovinceid"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="城市" prop="city_id">
              <el-select
                v-model="ruleForm.city_id"
                :disabled="!!ruleForm.veg_id"
                placeholder="请选择城市"
              >
                <el-option
                  v-for="item in Form.cityid.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="cityidchildren"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="区域">
              <el-select
                v-model="ruleForm.area_id"
                :disabled="!!ruleForm.veg_id"
                placeholder="请选择区域"
              >
                <el-option
                  v-for="item in Form.areaid.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="areaidchildren"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="街道">
              <el-select
                v-model="ruleForm.street_id"
                :disabled="!!ruleForm.veg_id"
                placeholder="请选择街道"
              >
                <el-option
                  v-for="item in Form.streetid.children"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="电子秤编号" prop="device_id">
              <el-select
                v-model="ruleForm.device_id"
                clearable
                placeholder="请选电子秤编号"
              >
                <el-option
                  v-for="item in Form.deviceid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item class="photos" prop="plu" label="电子秤商品序号(plu)">
              <el-input
                style="width:217px"
                placeholder="请填写电子秤商品序号(plu)"
                v-model="ruleForm.plu"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="快捷键" prop="key">
              <el-input
                style="width:217px"
                placeholder="请填写电子秤快捷键"
                v-model="ruleForm.key"
              ></el-input>
            </el-form-item>
            <el-form-item label="ean13" prop="ean13">
              <el-input
                style="width:217px"
                placeholder="请填写 ean13"
                v-model="ruleForm.ean13"
              ></el-input>
            </el-form-item>
            <el-form-item label="进货价格" prop="prime_cost">
              <el-input
                style="width:217px"
                placeholder="请填写进货价格"
                v-model="ruleForm.prime_cost"
              >
                <i slot="suffix" style="font-style:normal">元/{{ unit }}</i>
              </el-input>
            </el-form-item>
            <el-form-item label="供应商" prop="supplier_id">
              <el-select
                v-model="ruleForm.supplier_id"
                clearable
                placeholder="请填写供应商"
              >
                <el-option
                  v-for="item in Form.supplierid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品状态">
              <el-radio v-model="ruleForm.veg_status" label="1">上架</el-radio>
              <el-radio v-model="ruleForm.veg_status" label="0">下架</el-radio>
            </el-form-item>
            <el-form-item label="进货凭证" prop="stock_certificate">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFiles"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.stock_certificate"
                  :src="ruleForm.stock_certificate"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
        </div>
        <div style="padding:0 40px">
          <el-form-item label="商品描述">
            <el-input
              type="textarea"
              resize="none"
              style="width:100%"
              v-model="ruleForm.veg_describe"
              placeholder="请输入商品描述200字以内"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="计量单位列表" :visible.sync="add2" width="40%" center>
      <el-table
        :data="Form.unitid.data"
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column prop="units_name" label="计重单位名称">
        </el-table-column>
        <el-table-column prop="units" label="计重单位"> </el-table-column>
        <el-table-column prop="created_at" label="创建时间"> </el-table-column>
        <el-table-column prop="updated_at" label="更新时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleNnits(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-button @click="add2 = false">取 消</el-button>
        <el-button type="primary" @click="add3units">添 加</el-button>
      </div>
      <el-dialog
        width="30%"
        :title="unitsForm.tilteunits"
        :visible.sync="add3"
        append-to-body
        center
        @close="unitsclose"
      >
        <el-form
          :model="unitsForm"
          :rules="rulesunits"
          ref="unitsForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="计量单位名称" prop="units_name">
            <el-input
              style="width:217px"
              placeholder="请输入计量单位名称"
              v-model="unitsForm.units_name"
            ></el-input>
          </el-form-item>
          <el-form-item label="计量单位" prop="units">
            <el-input
              style="width:217px"
              placeholder="请输入计量单位(kg)"
              v-model="unitsForm.units"
            ></el-input>
          </el-form-item>
          <div style="width:100%;text-align:right">
            <el-button type="primary" @click="submitForm('unitsForm')"
              >确 定</el-button
            >
          </div>
        </el-form>
      </el-dialog>
    </el-dialog>
    <el-dialog title="商品批量导入" :visible.sync="addexlx" width="40%" center>
      <el-form
        :model="exlx"
        :rules="exlxs"
        ref="exlxs"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="所在市场" prop="region">
          <el-select v-model="exlx.mk_id" placeholder="请选择市场">
            <el-option
              v-for="item in Form.market"
              :key="item.id"
              :label="item.comp_name"
              :value="item.id"
              @click.native="clickmarket"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经营户" prop="region">
          <el-select v-model="exlx.seller_id" clearable placeholder="请选择">
            <el-option
              v-for="item in Form.SellerByMarket"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              @click.native="clickSellerByMarket('1')"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺" prop="region">
          <el-select v-model="exlx.shop_id" clearable placeholder="请选择店铺">
            <el-option
              v-for="item in Form.ShopBySellerId"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              @click.native="Shop_BySeller_Id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品分类" prop="region">
          <el-select v-model="exlx.product_types_id" placeholder="商品分类">
            <el-option
              v-for="item in Form.CategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品库商品">
          <el-cascader
            :options="cascader"
            :props="{ value: 'id', label: 'name', multiple: true }"
            collapse-tags
            clearable
            v-model="exlx.veg_ids"
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submits">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <DialogImg v-show="0" ref="img" />
  </div>
</template>

<script>
import { getmarket, provinceCityArea, getcity } from '../../../api/layout'
import { upload } from '../../../utile/upload'
import {
  GETproductSellers,
  getSellerByMarketId,
  GETunits,
  POSTproductSellers,
  GETsupplier,
  POSTunits,
  PUTunits,
  getShopBySellerId,
  getTypeByShopId,
  getProductByName,
  PUTproductSellers,
  getDeviceList,
  getCategoryList,
  addSellerProduct
} from '../../../api/productList'
import { superiorCategoryList } from '../../../api/backstageList'
export default {
  name: 'agencyList',
  data () {
    return {
      options: '',
      options1: '',
      loading: false,
      addexlx: false,
      indexsls: 1,
      value: {
        value1: '', // 市场
        value2: '',
        value3: '',
        value4: ''
      },
      cascader: '',
      tableData: '', // 表格数据
      add: false, // 弹框标识
      add2: false, // 弹框标识
      add3: false, // 弹框标识
      unit: '', // 计量单位
      productsellertypes: '',
      page: 1,
      limit: 10,
      title: '添加经营户商品',
      handleEditID: '', // 编辑的id
      // 弹框数据
      Form: {
        market: '', // 市场
        SellerByMarket: '', // 经营户
        ShopBySellerId: '', // 店铺
        CategoryList: '', // 商品分类
        provinceid: '', // 省份
        cityid: '', // 城市
        areaid: '', // 区域
        streetid: '', // 街道
        unitid: '', // 计量单位
        supplierid: '', //  供应商
        vegid: [], // 商品库商品
        deviceid: '' // 电子秤编号
      },
      rules: {
        market_id: [{ required: true, message: '请选择市场', trigger: 'blur' }],
        seller_id: [
          { required: true, message: '请选择经营户', trigger: 'blur' }
        ],
        product_types_id: [
          { required: true, message: '请选择商品分类', trigger: 'blur' }
        ],
        veg_name: [
          { required: true, message: '请填写商品名称', trigger: 'blur' }
        ],
        province_id: [
          { required: true, message: '请选择省份', trigger: 'blur' }
        ],
        city_id: [{ required: true, message: '请选择城市', trigger: 'blur' }],
        veg_logo: [
          { required: true, message: '请上传商品图片', trigger: 'blur' }
        ],
        unit_id: [
          { required: true, message: '请选择计量单位', trigger: 'blur' }
        ],
        sku: [{ required: true, message: '请填写商品数量', trigger: 'blur' }],
        veg_price: [
          { required: true, message: '请填写销售单价', trigger: 'blur' }
        ],
        key: [
          { required: true, message: '请填写电子秤快捷键', trigger: 'blur' }
        ],
        prime_cost: [
          { required: true, message: '请填写进货价格', trigger: 'blur' }
        ],
        supplier_id: [
          { required: true, message: '请填写供应商', trigger: 'blur' }
        ],
        stock_certificate: [
          { required: true, message: '请上传进货凭证', trigger: 'blur' }
        ],
        device_id: [
          { required: true, message: '请选电子秤编号', trigger: 'blur' }
        ],
        shop_id: [{ required: true, message: '请选择店铺', trigger: 'blur' }]
      },
      ruleForm: {
        market_id: '', // 市场id
        seller_id: '', // 经营户
        shop_id: '', // 店铺
        product_types_id: '', // 商品分类
        veg_id: '', // 商品库商品ID 没有传0 否
        veg_name: '', // 商品名称
        veg_alias_name: '', // 商品别名
        province_id: '', //  省份ID
        city_id: '', // 城市ID
        area_id: '', // 区域ID 否
        street_id: '', // 街道ID  否
        veg_logo: '', // 商品图片
        unit_id: '', // 计量单位
        sku: 0, // 商品数量
        veg_price: '', // 销售单价
        plu: 0, // 电子秤商品序号(plu)
        key: '', // 快捷键
        ean13: '', // ean13
        prime_cost: '', // 进货价格
        supplier_id: '', // 供应商ID
        stock_certificate: '', // 进货凭证
        veg_describe: '', // 商品描述 否
        veg_status: '1', // 商品状态 0下架 1上架
        device_id: '', // 电子秤编号
        veg_code: '' // 商品编号
      },
      unitsForm: {
        units_name: '', // 计量单位名称
        units: '', // 计量单位
        id: '',
        tilteunits: '添加计量单位'
      },
      rulesunits: {
        units_name: [
          { required: true, message: '请输入计量单位名称请', trigger: 'blur' }
        ],
        units: [{ required: true, message: '请输入计量单位', trigger: 'blur' }]
      },
      exlx: {
        mk_id: '',
        seller_id: '',
        shop_id: '',
        product_types_id: '',
        product_types_name: '',
        veg_ids: []
      },
      exlxs: {}
    }
  },
  methods: {
    // 市场
    get_market () {
      getmarket().then(res => {
        if (res.code === 200) {
          this.Form.market = res.data
        }
      })
    },
    // 选择啦市场
    clickmarket () {
      this.ruleForm.seller_id = ''
      this.ruleForm.shop_id = ''
      this.ruleForm.product_types_id = ''
      this.ruleForm.device_id = ''
      this.exlx.seller_id = ''
      this.exlx.shop_id = ''
      this.exlx.product_types_id = ''
      getSellerByMarketId(this.ruleForm.market_id || this.exlx.mk_id).then(
        res => {
          this.Form.SellerByMarket = res.data
        }
      )
    },
    // 选择啦经营户
    async clickSellerByMarket (bol) {
      this.ruleForm.shop_id = ''
      this.ruleForm.product_types_id = ''
      this.ruleForm.device_id = ''
      this.exlx.shop_id = ''
      this.exlx.product_types_id = ''
      await getShopBySellerId(
        this.ruleForm.seller_id || this.exlx.seller_id
      ).then(res => {
        this.Form.ShopBySellerId = res.data
      })
      if (bol == 1) return
      getDeviceList(
        this.ruleForm.seller_id || this.exlx.seller_id,
        this.ruleForm.market_id || this.exlx.mk_id
      ).then(res => {
        this.Form.deviceid = res.data
      })
      if (bol == 1) return
      this.GET_uppliers()
    },
    // 选择啦店铺
    Shop_BySeller_Id () {
      this.ruleForm.product_types_id = ''
      this.ruleForm.device_id = ''
      getTypeByShopId(this.ruleForm.shop_id || this.exlx.shop_id).then(res => {
        this.Form.CategoryList = res.data
      })
    },
    // 表格数据
    GET_productSellers ({ page = '', limit = 10 } = {}) {
      GETproductSellers({ page, limit }).then(res => {
        this.tableData = res.data
      })
    },
    // 搜索商品库的数据
    remoteMethod (query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          getProductByName(query).then(res => {
            this.Form.vegid = res.data
          })
        }, 200)
      } else {
        this.Form.vegid = []
      }
    },
    // 清除
    closeappend () {
      this.ruleForm = {
        market_id: '', // 市场id
        seller_id: '', // 经营户
        shop_id: '', // 店铺
        product_types_id: '', // 商品分类
        veg_id: '', // 商品库商品ID 没有传0 否
        veg_name: '', // 商品名称
        veg_alias_name: '', // 商品别名
        province_id: '', //  省份ID
        city_id: '', // 城市ID
        area_id: '', // 区域ID 否
        street_id: '', // 街道ID  否
        veg_logo: '', // 商品图片
        unit_id: '', // 计量单位
        sku: '', // 商品数量
        veg_price: '', // 销售单价
        plu: 0, // 电子秤商品序号(plu)
        key: '', // 快捷键
        ean13: '', // ean13
        prime_cost: '', // 进货价格
        supplier_id: '', // 供应商ID
        stock_certificate: '', // 进货凭证
        veg_describe: '', // 商品描述 否
        veg_status: '1', // 商品状态 0下架 1上架
        veg_code: '' // 商品编号
      }
      this.Form.provinceid = ''
      this.Form.unitid = ''
      this.Form.supplierid = ''
    },
    // 搜索点击
    async searchvegid (item) {
      this.ruleForm.veg_id = item.id
      this.ruleForm.veg_name = item.veg_name
      this.ruleForm.veg_alias_name = item.veg_alias_name
      this.ruleForm.veg_logo = item.veg_logo
      this.ruleForm.province_id = item.province_id
      this.ruleForm.veg_code = item.veg_code
      await this.getprovinceid()
      this.ruleForm.city_id = item.city_id
      await this.cityidchildren()
      this.ruleForm.area_id = item.area_id ? item.area_id : ''
      await this.areaidchildren()
      this.ruleForm.street_id = item.street_id ? item.street_id : ''
    },
    // 搜索清除
    clearvegname () {
      this.ruleForm.veg_id = ''
      this.ruleForm.veg_name = ''
      this.ruleForm.veg_alias_name = ''
      this.ruleForm.veg_logo = ''
      this.ruleForm.province_id = ''
      this.ruleForm.city_id = ''
      this.ruleForm.area_id = ''
      this.ruleForm.street_id = ''
      this.Form.cityid = ''
      this.Form.areaid = ''
      this.Form.streetid = ''
      this.ruleForm.veg_code = ''
    },
    // 编辑按钮
    async handleEdit (row) {
      this.GET_units()
      this.GET_provinceCityArea()
      this.title = '编辑经营户商品'
      this.add = true
      this.handleEditID = row.id
      this.ruleForm.veg_name = row.product.veg_name
      this.ruleForm.veg_id = row.veg_id
      this.ruleForm.veg_alias_name = row.product.veg_alias_name
      this.ruleForm.province_id = row.product.province_id
      this.ruleForm.market_id = row.market_id
      this.ruleForm.veg_logo = row.product.veg_logo
      this.ruleForm.unit_id = row.unit.id
      this.unit = row.unit.units
      this.ruleForm.sku = row.sku
      this.ruleForm.veg_price = row.veg_price
      this.ruleForm.plu = row.plu
      this.ruleForm.key = row.key
      this.ruleForm.ean13 = row.ean13
      this.ruleForm.prime_cost = row.prime_cost
      this.ruleForm.supplier_id = row.supplier_id
      this.ruleForm.stock_certificate = row.stock_certificate
      this.ruleForm.veg_describe = row.veg_describe
      this.ruleForm.veg_status = `${row.veg_status}`
      this.ruleForm.veg_code = row.product.veg_code
      await this.clickmarket()
      this.ruleForm.seller_id = row.seller_id
      await this.clickSellerByMarket()
      this.ruleForm.shop_id = row.shop_id
      await this.Shop_BySeller_Id()
      this.ruleForm.product_types_id = row.product_types_id
      await this.getprovinceid()
      this.ruleForm.city_id = row.product.city_id
      await this.cityidchildren()
      this.ruleForm.area_id = row.product.area_id ? row.product.area_id : ''
      await this.areaidchildren()
      this.ruleForm.device_id = `${row.device_id}`
      this.ruleForm.street_id = row.product.street_id
        ? row.product.street_id
        : ''
    },
    // 计量单位
    GET_units ({ page = '', limit = '' } = {}) {
      GETunits({ page, limit }).then(res => {
        this.Form.unitid = res.data
      })
    },
    // 省份
    GET_provinceCityArea () {
      provinceCityArea().then(res => {
        this.Form.provinceid = res.data
      })
    },
    // 选择啦省份
    getprovinceid () {
      getcity(this.ruleForm.province_id).then(res => {
        this.Form.cityid = res.data
      })
    },
    // 选择啦城市
    cityidchildren () {
      getcity(this.ruleForm.city_id).then(res => {
        this.Form.areaid = res.data
      })
    },
    // 选择啦区域
    areaidchildren () {
      getcity(this.ruleForm.area_id).then(res => {
        this.Form.streetid = res.data
      })
    },
    // 供应商
    GET_uppliers () {
      GETsupplier(this.ruleForm.seller_id).then(res => {
        if (res.code === 200) {
          this.Form.supplierid = res.data
        }
      })
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径
    uploadSectionFile (param) {
      // this.ruleForm.veg_logo = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.veg_logo = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 获取真实的图片路径
    uploadSectionFiles (param) {
      // this.ruleForm.stock_certificate = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.stock_certificate = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 新增按钮
    append () {
      this.add = true
      this.title = '添加经营户商品'
      this.GET_provinceCityArea()
      this.GET_units()
    },
    // 添加计量单位
    add3units () {
      this.add3 = true
      this.unitsForm.tilteunits = '添加计量单位'
    },
    // 计量单位  编辑按钮
    handleNnits (row) {
      this.unitsForm.tilteunits = '编辑计量单位'
      this.add3 = true
      this.unitsForm.units_name = row.units_name
      this.unitsForm.units = row.units
      this.unitsForm.id = row.id
    },
    unitsclose () {
      this.unitsForm.units_name = ''
      this.unitsForm.units = ''
      this.unitsForm.id = ''
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.limit = val
      this.GET_productSellers({ limit: val })
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.page = val
      this.GET_productSellers({ page: val })
    },
    // 表单确认按钮
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (formName === 'unitsForm') {
            const data = {
              units_name: this.unitsForm.units_name,
              units: this.unitsForm.units
            }
            if (this.unitsForm.id) {
              PUTunits(data, this.unitsForm.id).then(res => {
                this.add3 = false
                this.$message.success(`${res.data}`)
                this.GET_units()
              })
            } else {
              POSTunits(data).then(res => {
                this.add3 = false
                this.$message.success(`${res.data}`)
                this.GET_units()
              })
            }
          } else {
            const data = {
              ...this.ruleForm,
              veg_id: this.ruleForm.veg_id ? this.ruleForm.veg_id : 0,
              veg_describe: this.ruleForm.veg_describe
                ? this.ruleForm.veg_describe
                : '0',
              area_id: this.ruleForm.area_id ? this.ruleForm.area_id : 0,
              street_id: this.ruleForm.street_id ? this.ruleForm.street_id : 0
            }
            if (this.title === '添加经营户商品') {
              POSTproductSellers(data)
                .then(res => {
                  this.$message.success(`${res.data}`)
                  this.add = false
                  this.closeappend()
                  this.GET_productSellers({
                    page: this.page,
                    limit: this.limit
                  })
                })
                .catch(err => {
                  this.$message.error(`${err.msg}`)
                })
            } else {
              PUTproductSellers(data, this.handleEditID).then(res => {
                this.$message.success(`${res.data}`)
                this.add = false
                this.GET_productSellers({
                  page: this.page,
                  limit: this.limit
                })
              })
            }
          }
        } else {
          return false
        }
      })
    },
    get_CategoryList () {
      getCategoryList().then(res => {
        this.cascader = res.data
      })
    },
    indexsl (index) {
      this.indexsls = index
      this.exlx.veg_ids = []
    },
    GET_productsellertypes () {
      superiorCategoryList({ seller_id: this.exlx.shop_id }).then(res => {
        this.productsellertypes = res.data
      })
    },
    submits () {
      let veg_ids = this.exlx.veg_ids.flat(Infinity),
        vegids = []
      veg_ids.sort((a, b) => {
        return a - b
      })
      for (var i = 0; i < veg_ids.length; i++) {
        if (vegids.indexOf(veg_ids[i]) === -1) {
          vegids.push(veg_ids[i])
        }
      }
      this.cascader.forEach(item1 => {
        vegids.forEach((item2, index) => {
          if (item1.id == item2) {
            vegids.splice(index, 1)
          }
        })
      })
      console.log(vegids)
      const data = {
        ...this.exlx,
        veg_ids: vegids
      }
      addSellerProduct(data).then(res => {
        console.log(res)
        this.addexlx = false
        this.$message.success('提交成功')
      })
    }
  },
  mounted () {
    this.get_market()
    this.GET_productSellers()
    this.get_CategoryList()
  }
}
</script>

<style lang="less" scoped>
.between {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
/deep/.has-gutter th {
  padding: 0;
}
/deep/ .photos .el-form-item__label {
  line-height: 14px;
}
.betten {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.footer {
  width: 100%;
  text-align: right;
  margin-top: 20px;
}
.footers {
  text-align: right;
  margin-top: 10px;
}
</style>
